export default defineNuxtRouteMiddleware(async (to, from) => {
  const user = useSupabaseUser();
  const client = useSupabaseClient();

  const allowedRoutes = ["/sign-in", "/create-account", "/"];

  if (!user.value && !allowedRoutes.includes(to.path)) {
    return navigateTo("/sign-in");
  }

  if (!user.value && to.path === "/create-story") {
    return navigateTo("/sign-in");
  }

  if (user.value && to.path === "/") {
    return navigateTo("/stories");
  }

  if (user.value && from.path === "/sign-in") {
    return navigateTo("/sync");
  }
});
